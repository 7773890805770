/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';

// Lib UI components
import { Container, Row, Col, Visible } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import SideMenu from '../shared/UIKit/SideMenu';
import Cards from '../page-components/Profiles/Cards';
import SelectMenu from '../shared/UIKit/SelectMenu';
import Loader from '../shared/UIKit/Loader';

// helpers
import { getToken } from '../shared/Services/auth';
import { FavoriteProfilesEndPoint } from '../shared/APIs';

// Style
import '../page-styles/favorites.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Favorites({ location }) {
  /* ********************************** HOOKS ********************************* */
  const [favoriteProfileList, setFavoriteProfileList] = React.useState([]);
  const [triggerUpdate, setTriggerUpdate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // Localization
  const { t } = useTranslation();
  const { title } = t('Favorites', { returnObjects: true });

  async function getFavoriteProfileList() {
    setIsLoading(true);
    const { data } = await axios.get(FavoriteProfilesEndPoint(), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    setFavoriteProfileList(data.data);
    setIsLoading(false);
  }

  const updateFavoriteList = React.useCallback(() => {
    setTriggerUpdate((trigger) => !trigger);
  }, []);

  React.useEffect(() => {
    getFavoriteProfileList();
  }, [triggerUpdate]);

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Favorites" />
      <Container className="favorites" fluid>
        <Visible xxl xl lg>
          <Row>
            <Col xl={3} lg={3} md={3}>
              <SideMenu pageName={title} visitedPage={location.pathname} />
            </Col>
            <Col xxl={9} xl={9} md={12} sm={12} className="cards-container">
              {isLoading ? (
                <Loader />
              ) : (
                <Cards
                  updateFavoriteList={updateFavoriteList}
                  profiles={favoriteProfileList}
                />
              )}
            </Col>
          </Row>
        </Visible>
        <Visible md sm xs>
          <SelectMenu visitedPage={title} />
          {isLoading ? (
            <Loader />
          ) : (
            <Cards
              updateFavoriteList={updateFavoriteList}
              profiles={favoriteProfileList}
            />
          )}
        </Visible>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Favorites", "Profiles", "Common", "Profile"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
Favorites.propTypes = {
  location: PropTypes.string,
};
Favorites.defaultProps = {
  location: {},
};
export default Favorites;
